
import { Component, Vue } from 'vue-property-decorator'
import { Page } from '../../types/common'
import { PageType } from '../../types/customer'
import { CustomerType } from '@/utils/enum'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'Customer',
  filters: {
    filterArea (value: {[key: string]: string}) {
      let str = value.province + value.city + value.area
      str = value.address ? str + value.address : str
      return str
    }
  }
})
@KeepAlive
export default class extends Vue {
  private fullscreenLoading = false
  private searchInfo = {
    customerName: '',
    versionId: '',
    agentId: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '客户名称',
        prop: 'customerName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '客户类型',
        prop: 'customerType',
        minWidth: '100'
      },
      {
        label: '客户状态',
        prop: 'customerStatusName',
        minWidth: '100'
      },
      {
        label: '客户地址',
        prop: 'province',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '版本名称',
        prop: 'versionName',
        minWidth: '100'
      },
      {
        label: '系统账号',
        prop: 'adminAccount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '有效期',
        prop: 'validDate',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '创建人',
        prop: 'createUserName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '所属代理商',
        prop: 'agentName',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  private menuList = []
  private agentList: Array<{ agentId: string; agentName: string }> = []

  customerType = CustomerType

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
    this.getMenuList()
    this.getAgentList()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.customer.customerPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  // 查询版本列表
  getMenuList () {
    this.$axios.get(this.$apis.version.versionList)
      .then((res) => {
        this.menuList = res.list || []
      })
  }

  // 获取代理商列表
  getAgentList () {
    this.$axios.get(this.$apis.agent.selectAgentByList, {}).then((res) => {
      this.agentList = res.list || []
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  customerAdd () {
    this.$router.push({
      name: 'customerAdd'
    })
  }

  // 详情
  onDetails (id: string) {
    this.$router.push({
      name: 'customerDetail',
      params: { id }
    })
  }

  // 编辑
  onUpdate (id: string) {
    this.$router.push({
      name: 'customerUpdate',
      params: { id }
    })
  }

  // 激活
  onActivation (id: string) {
    this.$confirm('确认激活吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.fullscreenLoading = true
      this.$axios.post(this.$apis.customer.customerActivation, {
        customerId: id
      })
        .then(() => {
          this.$message({
            message: '激活成功',
            type: 'success'
          })
          this.getData()
        }).finally(() => {
          this.fullscreenLoading = false
        })
    })
      .catch((err) => {
        console.log(err)
      })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.customer.customerDelete, {
        customerId: id
      })
        .then(() => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getData()
        })
    })
      .catch((err) => {
        console.log(err)
      })
  }
}
